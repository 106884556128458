import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const RunRegisterNowButton = ({
  theme = 'red',
  url = '',
  title,
  target = '_blank',
  locale
}) => {
  return (
    <a
      href={url}
      target={target}
      className={`button-wrapper run-register-now-button ${
        theme === 'white' ? 'white-theme' : ''
        }`}
    >
      <div className="text-wrapper">
        <p className={locale}>{title ? title : 'Register Now'}</p>
      </div>
    </a>
  )
}

export default RunRegisterNowButton