import React, { useState } from 'react'
import './styles.scss'

export default ({ children, title, locale }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`collapse-item ${isOpen ? 'active' : ''}`}>
      <div onClick={() => setIsOpen(!isOpen)} className="collapse-item-title">
        <p className="section-normal-text chevron-icon">></p>
        <p className={`section-normal-text text-bold ${locale}`}>{title}</p>
      </div>
      <div className="section-normal-text collapse-item-children">
        <div className={`children-wrapper ${locale}`}>{children}</div>
      </div>
    </div>
  )
}
