import PropTypes from "prop-types"
import React from "react"
import parse from 'html-react-parser'
import background_title from 'src/images/prumdrt2020/bg_title.png'
import "./styles.scss"

const BoxShareWithPhoto = ({ box_content, text_scan_qr_code_text, locale }) => (
  <div className="group-box">
    <div className="group-with-image">
      <div className={`section-title new-section-title ${locale}`} >
        <div className="title-bg-run text-capitalize" style={{ backgroundImage: `url(${background_title})` }}>{box_content.title}</div>
      </div>
      <div className="image-on-box"><img src={box_content.image} /></div>
      <div className="image-qr-code-on-box"><img src={box_content.qr_code} /></div>
      <div className={`text-with-link ${locale}`}>
        <p>{parse(text_scan_qr_code_text.replace('[:url:]', box_content.qr_code_url).replace('[:optional_attr:]', 'target="_blank"'))}</p>
      </div>
    </div>
  </div>
)

export default BoxShareWithPhoto